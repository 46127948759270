import Head from "next/head";
import WebsiteLayout from "../components/website/Layout";
import Link from "next/link";
import { HiCheck, HiChevronLeft } from "react-icons/hi";
import {
  FcApproval,
  FcCalendar,
  FcCheckmark,
  FcClock, FcComboChart,
  FcConferenceCall,
  FcExpired,
  FcHighPriority,
  FcLike,
  FcRefresh,
  FcSms, FcTimeline,
  FcViewDetails,
  FcVoicePresentation
} from "react-icons/fc";
import React from "react";

export default function Keyword({ keyword, displayed }) {
  return (
    <>
      <Head>
        <title key='maintitle'>זמן טוב - {displayed}</title>
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta name="description" key='description' content={`האפליקציה המתקדמת, היעילה והחכמה ביותר לעסקים הדורשים ניהול לקוחות וזימון תורים | ${displayed}`} />
        <meta key='keywords' name="keywords"
          content="ניהול תורים, ניהול לקוחות, מערכת קביעת תורים לעסק, ניהול תורים לעסק, אפליקציה לעסק" />
        <meta key='ogtitle' property="og:title"
          content={`זמן טוב - ${displayed}`} />
        <meta key='ogdescription' property="og:description"
          content={`האפליקציה המתקדמת, היעילה והחכמה ביותר לעסקים הדורשים ניהול לקוחות וזימון תורים | ${displayed}`} />
        <meta key='ogurl' property="og:url" content={`https://zmantov.co.il/${keyword}/`} />
        <link key='canonical' rel="canonical" href={`https://zmantov.co.il/${keyword}/`} />
      </Head>

      <div className="max-w-6xl mx-auto" id='main'>
        <div className="pt-12 sm:py-12 flex flex-col sm:space-y-8">

          <div className="w-full flex flex-col justify-center items-center">
            <div className="max-w-sm md:max-w-2xl space-y-8 w-5/6 mx-auto md:w-full text-center">
              <div>
                <div className="flex-col md:flex-row border-2 border-green-500 rounded-lg py-3 px-6 mb-4 text-base text-green-500 flex items-center justify-center" role="alert">
                  <span className='font-medium bg-green-500 text-white px-2 pl-1.5 flex items-center justify-center rounded text-sm mb-3 md:mb-0 md:ml-2'>חדש ⭐</span> האפליקציה היחידה בארץ עם תזכורות אוטומטיות דרך ה-WhatsApp!
                </div>
              </div>

              <h1
                className="font-primary font-extrabold text-gray-900 text-5xl sm:text-4xl md:text-6xl md:leading-tighter tracking-tight">מחפשים {displayed}?
              </h1><p
                className="font-secondary leading-tight text-gray-500 text-xl md:text-2xl lg:text-2xl tracking-tight">
                האפליקציה הפשוטה והמתקדמת ביותר לניהול לקוחות וקביעת תורים לעסק שלך. הצטרפו ליותר מ3700
                בעלי עסקים שכבר נהנים מיומן עבודה שמתמלא לבד, וחוסכים זמן וכאב ראש.
              </p>

              {/*<div className="sm:max-w-xs w-full mx-auto md:w-full text-center flex justify-center">*/}
              {/*  <div className='text-base text-center tracking-tight text-gray-500 items-center border-2 rounded-full flex mt-2 px-2  pl-3'>*/}
              {/*    <img className='w-12 h-12 ml-2 p-1' src='/img/irs-badge.svg'/>*/}
              {/*    <div className='text-gray-500 flex flex-col items-start'>*/}
              {/*      <span className='flex items-center'><FcApproval size={25} className='ml-2'/>מאושרת ע״י רשות המיסים</span>*/}
              {/*    </div>*/}
              {/*  </div>*/}
              {/*</div>*/}

              <div className='space-y-4'>
                <div className="hidden md:flex justify-center flex-col items-center">
                  <Link href='/app/signup'>
                    <button
                      className="bg-green-500 text-lg px-6 py-3 border border-green-500 hover:bg-green-600 text-white transition duration-500 font-semibold ease-in-out hover:rounded rounded-lg">
                      צרו חשבון לעסק שלכם - חינם!
                    </button>
                  </Link>
                  <Link href='/app/login'>
                    <a className="text-gray-500 mt-4 underline font-bold tracking-tight">כבר יש לכם חשבון? לחצו להתחברות</a>
                  </Link>
                </div>

                <div className="flex md:hidden justify-center flex-col items-center">
                  <Link href='/download/'>
                    <button
                      className="w-full bg-green-500 text-lg px-6 py-3 border border-green-500 hover:bg-green-600 text-white transition duration-500 font-semibold ease-in-out hover:rounded rounded-lg">
                      הורידו עכשיו!
                    </button>
                  </Link>
                </div>

                <div className="justify-center inline-flex flex-col items-center space-y-2 p-2">
                  <div className='flex  '>
                    <img className="inline-block w-10 h-10 rounded-full ring-2 -mr-1 ring-gray-200"
                      src='https://zmantov.fra1.digitaloceanspaces.com/logos/logo-1.jpg'
                      alt="" />
                    <img className="inline-block w-10 h-10 rounded-full ring-2 -mr-1 ring-gray-200"
                      src='https://zmantov.fra1.digitaloceanspaces.com/logos/logo-2.png'
                      alt="" />
                    <img className="inline-block w-10 h-10 rounded-full ring-2 -mr-1 ring-gray-200"
                      src='https://zmantov.fra1.digitaloceanspaces.com/logos/logo-3.jpg'
                      alt="" />
                    <img className="inline-block w-10 h-10 rounded-full ring-2 -mr-1 ring-gray-200"
                      src='https://zmantov.fra1.digitaloceanspaces.com/logos/logo-4.jpg'
                      alt="" />
                    <img className="inline-block w-10 h-10 rounded-full ring-2 -mr-1 ring-gray-200"
                      src='https://zmantov.fra1.digitaloceanspaces.com/logos/logo-5.jpg'
                      alt="" />
                    <img className="inline-block w-10 h-10 rounded-full ring-2 -mr-1 ring-gray-200"
                      src='https://zmantov.fra1.digitaloceanspaces.com/logos/logo-6.jpg'
                      alt="" />
                    <img className="inline-block w-10 h-10 rounded-full ring-2 -mr-1 ring-gray-200"
                      src='https://zmantov.fra1.digitaloceanspaces.com/logos/logo-7.jpg'
                      alt="" />
                    <img className="inline-block w-10 h-10 rounded-full ring-2 -mr-1 ring-gray-200"
                      src='https://zmantov.fra1.digitaloceanspaces.com/logos/logo-8.jpg'
                      alt="" />
                  </div>
                  <div className='text-gray-600 text-base pt-1 tracking-tight'><strong> 3700+ </strong>
                    עסקים הצטרפו לאחרונה
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="text-center fade-in fade-in-second pt-6">
            <div>
              <div className="hero-mockup w-5/6 sm:col-4/6 w-4/5 lg:w-full mx-auto relative">
                <img src="/img/hero-device.png" alt="Hero Device"
                  className="md:max-w-xl lg:max-w-4xl mx-auto relative" />
              </div>
            </div>
          </div>
          {/*testimonials*/}
          <div className="pt-12 pb-6 sm:pt-16 sm:pb-12">
            <div className="w-full px-2 max-w-6xl mx-auto">
              <div className="pb-4 lg:pb-8 pt-20">
                <div className="lg:w-full text-center">
                  <h2 className="text-4xl md:text-4xl font-bold text-gray-700 px-3">זמן טוב: {displayed}</h2>
                  <p className="py-3">מאות בעלי עסקים כבר הבינו את זה, שכל היום לדבר עם לקוחות זה לא עסק. המערכת שלנו
                    תטפל בהכל, ואתם תעשו את מה שאתם טובים בו.</p>
                </div>
              </div>
              <div
                className="flex flex-col items-center pt-12 lg:space-y-8 space-y-5 ">
                <div className='flex flex-col lg:flex-row space-y-5 lg:space-y-0 lg:items-center lg:space-x-8 lg:space-x-reverse'>
                  <div
                    className="h-80 w-72 bg-white shadow-lg border border-palette-lighter p-6 rounded-lg transform transition ease-in hover:scale-110 duration-200">
                    <img className='w-1/2 mx-auto pb-4' src='/img/stars.jpg' />
                    <p className="text-base text-center sm:text-lg text-gray-500 font-secondary">״פשוט תודה ענקית לכם! סוף סוף יש לי חיים ושקט ואני לא צריכה להתעסק כל היום בתורים, שינויים וביטולים!״</p>
                    <div className="flex items-center pt-6"><img height="80" width="80"
                      src="https://i.imgur.com/T7WnyMU.jpg"
                      alt="testimonial-avatar-1"
                      className="shadow border flex-shrink-0 h-20 w-20 rounded-full object-cover" />
                      <div className="flex flex-col mr-4 font-primary"><h2
                        className="text leading-6 font-bold text-gray-900">קסם בוהדנה</h2><p
                          className="text-sm text-gray-700 direction-ltr">@kesem_buhadana</p></div>
                    </div>
                  </div>

                  <div
                    className="h-80 w-72 bg-white  shadow-lg border border-palette-lighter p-6 rounded-lg transform transition ease-in hover:scale-110 duration-200">
                    <img className='w-1/2 mx-auto pb-4' src='/img/stars.jpg' />
                    <p className="text-base text-center sm:text-lg text-gray-500 font-secondary">״האפליקציה הזאת פשוט מסדרת חיים! היא נוחה ברמות, ברורה ללקוחות, מזל שפתאום היא קפצה לי באינסטגרם!״</p>
                    <div className="flex items-center pt-6"><img height="80" width="80"
                      src="https://i.imgur.com/dDe24q6.jpg"
                      alt="testimonial-avatar-1"
                      className="shadow border flex-shrink-0 h-20 w-20 rounded-full object-cover" />
                      <div className="flex flex-col mr-4 font-primary"><h2
                        className="text leading-6 font-bold text-gray-900">רוני ניילס</h2><p
                          className="text-sm text-gray-700 direction-ltr">@roni_nails9</p></div>
                    </div>
                  </div>

                  <div
                    className="h-80 w-72 bg-white shadow-lg border border-palette-lighter p-6 rounded-lg transform transition ease-in hover:scale-110 duration-200">
                    <img className='w-1/2 mx-auto pb-4' src='/img/stars.jpg' />
                    <p className="text-base text-center sm:text-lg text-gray-500 font-secondary">״וואו אני חייב לפרגן לכם על האפליקציה הזאת! מהירה, קלילה, נוחה, והכי חשוב - חוסכת לי המון זמן!!!״</p>
                    <div className="flex items-center pt-6"><img height="80" width="80"
                      src="https://zmantov.fra1.digitaloceanspaces.com/logos/logo-3.jpg"
                      alt="testimonial-avatar-1"
                      className="shadow border flex-shrink-0 h-20 w-20 rounded-full object-cover" />
                      <div className="flex flex-col mr-4 font-primary"><h2
                        className="text leading-6 font-bold text-gray-900">מתן אוזבנזר</h2><p
                          className="text-sm text-gray-700 direction-ltr">@ozbarberr</p></div>
                    </div>
                  </div>
                </div>

                <div className='flex flex-col lg:flex-row space-y-5 lg:space-y-0 lg:items-center lg:space-x-8 lg:space-x-reverse'>
                  <div
                    className="h-80 w-72 bg-white shadow-lg border border-palette-lighter p-6 rounded-lg transform transition ease-in hover:scale-110 duration-200">
                    <img className='w-1/2 mx-auto pb-4' src='/img/stars.jpg' />
                    <p className="text-base text-center sm:text-lg text-gray-500 font-secondary">״מעבר לכך שזה פשוט נוח, כל הלקוחות שלי החמיאו לי שהאפליקציה מוסיפה טאצ׳ מאוד מקצועי לעסק״</p>
                    <div className="flex items-center pt-6"><img height="80" width="80"
                      src="https://zmantov.fra1.digitaloceanspaces.com/logos/d0e5af51-323a-4b82-8704-ff3a2659f6c7.jpeg"
                      alt="testimonial-avatar-1"
                      className="shadow border flex-shrink-0 h-20 w-20 rounded-full object-cover" />
                      <div className="flex flex-col mr-4 font-primary"><h2
                        className="text leading-6 font-bold text-gray-900">אופק שלומי</h2><p
                          className="text-sm text-gray-700 direction-ltr">@ofeks.nails</p></div>
                    </div>
                  </div>
                  <div
                    className="h-80 w-72 bg-white shadow-lg border border-palette-lighter p-6 rounded-lg transform transition ease-in hover:scale-110 duration-200">
                    <img className='w-1/2 mx-auto pb-4' src='/img/stars.jpg' />
                    <p className="text-base text-center sm:text-lg text-gray-500 font-secondary ">
                      ״אין עליכם בעולם! האפליקציה סידרה לי לגמרי את החיים. הלקוחות שלי עפות על התזכורות ואני בעננים. תודה רבה רבה!״
                    </p>
                    <div className="flex items-center pt-6"><img height="80" width="80"
                      src="https://zmantov.fra1.digitaloceanspaces.com/logos/3f6d929c-05aa-4229-8442-194f806c9ef3.jpeg"
                      alt="testimonial-avatar-1"
                      className="flex-shrink-0 h-20 w-20 shadow border rounded-full object-cover" />
                      <div className="flex flex-col mr-4 font-primary"><h2
                        className="text leading-6 font-bold text-gray-900">נועם שרוני</h2><p
                          className="text-sm text-gray-700 direction-ltr">@_noam_nails</p></div>
                    </div>
                  </div>
                  <div
                    className="h-80 w-72 bg-white shadow-lg border border-palette-lighter p-6 rounded-lg transform transition ease-in hover:scale-110 duration-200">
                    <img className='w-1/2 mx-auto pb-4' src='/img/stars.jpg' />
                    <p className="text-base sm:text-lg text-center text-gray-500 font-secondary">״חודשים שחיפשתי משהו מקצועי וקל לתפעול ומזל שמצאתי אתכם. המערכת הכי נוחה ומדהימה בעולם!״</p>
                    <div className="flex items-center pt-6"><img height="80" width="80"
                      src="https://zmantov.fra1.digitaloceanspaces.com/logos/11df493a-bb3c-42b9-80f1-b0894b7a0524.jpeg"
                      alt="testimonial-avatar-1"
                      className="shadow border flex-shrink-0 h-20 w-20 rounded-full object-cover" />
                      <div className="flex flex-col mr-4 font-primary"><h2
                        className="text leading-6 font-bold text-gray-900">בר יפרח</h2><p
                          className="text-sm text-gray-700 direction-ltr">@barifrah_thebarber</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="pt-12 pb-6 sm:pt-16 sm:pb-12 my-10 text-center bg-gray-100 rounded-lg">
            <div className="w-full px-2 max-w-4xl mx-auto text-xl text-gray-600 space-y-3">
              <p>מערכת ניהול תורים היא חלק מכריע בכל עסק. היא יכולה לעזור לך לנהל את הזמן של הלקוחות שלך טוב יותר, להשתמש במשאבים שלך בצורה יעילה יותר ולשפר את שביעות רצון הלקוחות.</p>
              <p>                אפליקציה לקביעת תורים היא דרך קלה להגיע ללקוחות תוך כדי תנועה ולהקל על חייהם. עם תזכורת אוטומטית, לקוחות לא ישכחו להגיע לתור שלהם :)</p>
            </div>
          </div>

          <div className="space-y-6 sm:space-y-20 py-20">
            <div
              className="flex flex-col lg:flex-row-reverse justify-center items-center space-y-6 lg:space-y-0 max-w-5xl mx-auto lg:space-x-6">
              <div className="w-full lg:w-1/2 flex justify-center items-center"><img
                src="/img/screen-2.png" alt="benefit-2"
                className="w-64 flex sm:mr-auto sm:self-auto self-center sm:w-full sm:h-full max-w-md object-contain" />
              </div>
              <div className="w-11/12 mx-auto text-right lg:w-1/2 space-y-6 max-w-md">
                <div>
                  <h2 className="font-primary font-semibold text-right text-indigo-800">מתאימה לכולם</h2>
                  <h1 className="font-primary font-bold text-right text-4xl text-gray-800">{displayed}</h1>
                </div>
                <p
                  className="font-secondary text-lg text-gray-700">האפליקציה הפשוטה שלנו מתאימה לכל עסק
                  הדורש ניהול לקוחות וזימון תורים. אין יותר טלפונים :)</p>
                <ul className="font-secondary text-base text-gray-700 space-y-1">
                  <li className="flex space-x-4">
                    <HiCheck className="h-6 w-6 text-green-500 ml-1" />
                    <span>פשוטה לתפעול</span></li>
                  <li className="flex space-x-4">
                    <HiCheck className="h-6 w-6 text-green-500 ml-1" />
                    <span>דף עסקי ממותג עם כל פרטי העסק שלכם</span></li>
                  <li className="flex space-x-4">
                    <HiCheck className="h-6 w-6 text-green-500 ml-1" />
                    <span>נגישה מכל מקום 24/7</span></li>
                </ul>
                <div className='md:block hidden'>
                  <Link href='/app/signup'>
                    <a className='item-center content-center align-middle text-lg pr-4 font-bold text-green-500 p-2 inline-flex border-2 hover:bg-green-50 border-green-500 rounded-lg space-x-2 space-x-reverse'>
                      <div>הצטרפו בחינם</div>
                      <HiChevronLeft size={28} />
                    </a>
                  </Link>
                </div>
                <div className='md:hidden block'>
                  <Link href='/download/'>
                    <a className='item-center content-center align-middle text-lg pr-4 font-bold text-green-500 p-2 inline-flex border-2 hover:bg-green-50 border-green-500 rounded-lg space-x-2 space-x-reverse'>
                      <div>הצטרפו בחינם</div>
                      <HiChevronLeft size={28} />
                    </a>
                  </Link>
                </div>
              </div>
            </div>
            <div
              className="flex flex-col lg:flex-row justify-center text-right items-center space-y-6 lg:space-y-0 max-w-7xl mx-auto lg:space-x-6">
              <div
                className="w-full lg:w-1/2 flex justify-center items-center text-right "
              ><img src="/img/screen-1.png" alt="benefit-1"
                className="w-64 flex sm:ml-auto sm:self-auto self-center sm:w-full sm:h-full max-w-md object-contain" />
              </div>
              <div className="w-11/12 text-right mx-auto lg:w-1/2 space-y-6 max-w-md">
                <div>
                  <h2 className="font-primary font-semibold text-right text-indigo-800">מכל מקום ובכל
                    זמן</h2>
                  <h1 className="font-primary font-bold text-right text-4xl text-gray-800">ניהול תורים
                    חכם</h1>
                </div>
                <p
                  className="font-secondary text-lg text-gray-700">אפשרו ללקוחות שלכם לקבוע תור ביום
                  ובשעה המתאימה להם בלחיצת כפתור ומבלי להתקשר אליכם.</p>
                <ul className="font-secondary text-base text-gray-700 space-y-1">
                  <li className="flex space-x-2">
                    <HiCheck className="h-6 w-6 text-green-500 ml-1" />
                    <span>תזכורות אוטומטיות לפני התור</span></li>
                  <li className="flex space-x-2">
                    <HiCheck className="h-6 w-6 text-green-500 ml-1" />
                    <span>צפו בסטטיסטיקות לגבי העסק שלכם</span></li>
                  <li className="flex space-x-2">
                    <HiCheck className="h-6 w-6 text-green-500 ml-1" />
                    <span>נצלו את הזמן שלכם בצורה הטובה והיעילה ביותר</span></li>
                </ul>
                <div className='md:block hidden'>
                  <Link href='/app/signup'>
                    <a className='item-center content-center align-middle text-lg pr-4 font-bold text-green-500 p-2 inline-flex border-2 hover:bg-green-50 border-green-500 rounded-lg space-x-2 space-x-reverse'>
                      <div>הצטרפו בחינם</div>
                      <HiChevronLeft size={28} />
                    </a>
                  </Link>
                </div>
                <div className='md:hidden block'>
                  <Link href='/download/'>
                    <a className='item-center content-center align-middle text-lg pr-4 font-bold text-green-500 p-2 inline-flex border-2 hover:bg-green-50 border-green-500 rounded-lg space-x-2 space-x-reverse'>
                      <div>הצטרפו בחינם</div>
                      <HiChevronLeft size={28} />
                    </a>
                  </Link>
                </div>
              </div>
            </div>
            <div
              className="flex flex-col lg:flex-row-reverse justify-center items-center space-y-6 lg:space-y-0 max-w-5xl mx-auto lg:space-x-6">
              <div className=" w-full lg:w-1/2 flex justify-center items-center"><img
                src="/img/screen-3.png" alt="benefit-2"
                className="w-64 flex sm:mr-auto sm:self-auto self-center sm:w-full sm:h-full max-w-md object-contain" />
              </div>
              <div className=" w-11/12 mx-auto text-right lg:w-1/2 space-y-6 max-w-md">
                <div>
                  <h2 className="font-primary font-semibold text-right text-indigo-800">דואגים לעסק
                    שלכם</h2>
                  <h1 className="font-primary font-bold text-right text-4xl text-gray-800">שליטה
                    מלאה</h1>
                </div>
                <p
                  className="font-secondary text-lg text-gray-700">האפליקציה מגיעה עם כל הפיצ׳רים
                  והיכולות שיגרמו לעסק שלכם לגדול, להתייעל, ולמשוך עוד לקוחות.</p>
                <ul className="font-secondary text-base text-gray-700 space-y-1">
                  <li className="flex space-x-2">
                    <HiCheck className="h-6 w-6 text-green-500 ml-1" />
                    <span>הגדרת ימי חופשה, שעות פעילות ועוד</span></li>
                  <li className="flex space-x-2">
                    <HiCheck className="h-6 w-6 text-green-500 ml-1" />
                    <span>ניהול מס׳ נותני שירות במקביל</span></li>
                  <li className="flex space-x-2">
                    <HiCheck className="h-6 w-6 text-green-500 ml-1" />
                    <span>ניהול מבצעים ושירותי העסק</span></li>
                </ul>
                <div className='md:block hidden'>
                  <Link href='/app/signup'>
                    <a className='item-center content-center align-middle text-lg pr-4 font-bold text-green-500 p-2 inline-flex border-2 hover:bg-green-50 border-green-500 rounded-lg space-x-2 space-x-reverse'>
                      <div>הצטרפו בחינם</div>
                      <HiChevronLeft size={28} />
                    </a>
                  </Link>
                </div>
                <div className='md:hidden block'>
                  <Link href='/download/'>
                    <a className='item-center content-center align-middle text-lg pr-4 font-bold text-green-500 p-2 inline-flex border-2 hover:bg-green-50 border-green-500 rounded-lg space-x-2 space-x-reverse'>
                      <div>הצטרפו בחינם</div>
                      <HiChevronLeft size={28} />
                    </a>
                  </Link>
                </div>
              </div>
            </div>
          </div>

          {/*audience*/}
          <section className="py-20 sm:py-44">
            <div className="items-center max-w-6xl px-12 mx-auto sm:px-20 md:px-5">
              <div className="flex flex-wrap items-center -mx-3">
                <div className="order-1 w-full px-3 lg:w-1/2 lg:order-0">
                  <div className="w-full max-w-md xl:max-w-xl ">
                    <h2 className="relative font-primary font-bold text-4xl sm:text-4xl w-full text-gray-700">
                      בדיוק בשבילכם.
                    </h2>
                    <ul className='space-y-2 mt-10'>
                      <li className="flex items-center space-x-2 space-x-reverse">
                        <FcCheckmark size={20} />
                        <span className="font-normal text-gray-500">מעצבי שיער</span>
                      </li>
                      <li className="flex items-center space-x-2 space-x-reverse">
                        <FcCheckmark size={20} />
                        <span className="font-normal text-gray-500">קוסמטיקאיות</span>
                      </li>
                      <li className="flex items-center space-x-2 space-x-reverse">
                        <FcCheckmark size={20} />
                        <span className="font-normal text-gray-500">מומחי ביוטי</span>
                      </li>
                      <li className="flex items-center space-x-2 space-x-reverse">
                        <FcCheckmark size={20} />
                        <span className="font-normal text-gray-500">מאפרות</span>
                      </li>
                      <li className="flex items-center space-x-2 space-x-reverse">
                        <FcCheckmark size={20} />
                        <span className="font-normal text-gray-500">מאמני כושר</span>
                      </li>
                      <li className="flex items-center space-x-2 space-x-reverse">
                        <FcCheckmark size={20} />
                        <span className="font-normal text-gray-500">מורים פרטיים</span>
                      </li>
                      <li className="flex items-center space-x-2 space-x-reverse">
                        <FcCheckmark size={20} />
                        <span className="font-normal text-gray-500">יועצים</span>
                      </li>
                      <li className="flex items-center space-x-2 space-x-reverse">
                        <FcCheckmark size={20} />
                        <span className="font-normal text-gray-500">אתם.</span>
                      </li>
                    </ul>
                    <div className='md:block hidden mt-4'>
                      <Link href='/app/signup'>
                        <a
                          className='item-center content-center align-middle text-lg pr-4 font-bold text-green-500 p-2 inline-flex border-2 hover:bg-green-50 border-green-500 rounded-lg space-x-2 space-x-reverse'>
                          <div>הצטרפו בחינם</div>
                          <HiChevronLeft size={28} />
                        </a>
                      </Link>
                    </div>
                    <div className='md:hidden block mt-4'>
                      <Link href='/download/'>
                        <a
                          className='item-center content-center align-middle text-lg pr-4 font-bold text-green-500 p-2 inline-flex border-2 hover:bg-green-50 border-green-500 rounded-lg space-x-2 space-x-reverse'>
                          <div>הצטרפו בחינם</div>
                          <HiChevronLeft size={28} />
                        </a>
                      </Link>
                    </div>
                  </div>
                </div>
                <div
                  className="relative w-full px-3 mb-12 lg:-ml-20 lg:w-1/2 order-0 lg:order-1 lg:mb-0">
                  <img
                    className="absolute top-0 -right-10 z-10 hidden mx-auto -mt-32 rounded-lg shadow-2xl opacity-100 xl:-mr-12 sm:max-w-xs lg:max-w-sm lg:block"
                    src="https://images.unsplash.com/photo-1622286342621-4bd786c2447c?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1770&q=80" />
                  <img
                    className="relative z-20 w-full mx-auto mt-3 rounded-lg shadow-2xl sm:max-w-none lg:-ml-10 lg:max-w-sm"
                    src="https://images.unsplash.com/photo-1632345031435-8727f6897d53?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1770&q=80"
                    alt="feature image" />
                  <img
                    className="absolute bottom-0 right-0 z-10 hidden mx-auto -mb-48 rounded-lg shadow-2xl xl:-mr-12 sm:max-w-xs lg:max-w-sm lg:block"
                    src="https://images.unsplash.com/photo-1602910344008-22f323cc1817?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTV8fG1ha2V1cHxlbnwwfHwwfHw%3D&auto=format&fit=crop&w=800&q=60" />
                </div>
              </div>
            </div>
          </section>

          {/*steps*/}
          <div className="py-20">
            <div className="bg-gray-50 rounded-lg">
              <div className="max-w-6xl mx-auto py-12 sm:py-16"><h1
                className="text-center font-primary font-bold text-4xl sm:text-4xl w-full px-2 text-gray-700">התחילו
                בקלי קלות</h1>
                <div
                  className="flex flex-col lg:flex-row lg:justify-center items-center py-4 space-y-12 lg:space-y-0 lg:space-x-6">
                  <div
                    className="w-full lg::w-1/3 flex flex-col justify-between items-center max-w-md">
                    <img src="/img/how-it-works-1.svg" className="w-full object-contain"
                      alt="step 1 preview" />
                    <div className="mt-4 text-center"><h2
                      className="mb-2 font-bold text-xl md:text-2xl lg:text-xl text-gray-900 font-primary">צרו
                      חשבון</h2><p className="text-gray-500 font-secondary max-w-xs">השלימו את
                        תהליך ההרשמה הקצר והפשוט שלנו בכדי שנכיר אתכם קצת יותר - 3 דקות וסיימתם.</p>
                    </div>
                  </div>
                  <div
                    className="w-full lg::w-1/3 flex flex-col justify-between items-center max-w-md">
                    <img src="/img/how-it-works-2.svg" className="w-full object-contain"
                      alt="step 2 preview" />
                    <div className="mt-4 text-center"><h2
                      className="mb-2 font-bold text-xl md:text-2xl lg:text-xl text-gray-900 font-primary">הוסיפו
                      את העסק שלכם</h2><p className="text-gray-500 font-secondary max-w-xs">
                        הזינו את פרטי העסק שלכם, שעות פעילות, כתובת, וכו׳ על מנת ליצור את דף העסק
                        שלכם.
                      </p></div>
                  </div>
                  <div
                    className="w-full lg::w-1/3 flex flex-col justify-between items-center max-w-md">
                    <img src="/img/how-it-works-3.svg" className="w-full object-contain"
                      alt="step 3 preview" />
                    <div className="mt-4 text-center"><h2
                      className="mb-2 font-bold text-xl md:text-2xl lg:text-xl text-gray-900 font-primary">התחילו
                      לקבל תורים</h2><p className="text-gray-500 font-secondary max-w-xs">
                        שתפו את הלינק לדף העסק עם הלקוחות שלכם ואפשרו להם לקבוע תור בקלי קלות.
                      </p></div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/*features*/}
          <div className="text-center md:text-right py-16 px-4" id='features'>
            <div className="container mx-auto">
              <div className="pb-4 lg:pb-8 pt-20">
                <div className="lg:w-full text-center">
                  <h2 className="text-4xl md:text-4xl font-bold text-gray-700">כל מה שאתם צריכים -
                    במקום אחד</h2>
                  <p className="py-3">כן, כבר חשבנו על הכל בשביל העסק שלכם.</p>
                </div>
              </div>
              <div className="flex flex-col md:flex-row md:flex-wrap">
                {/*Feature*/}
                <div
                  className="w-full lg:w-1/4 md:w-1/2 text-center lg:text-right mt-4 flex flex-col justify-between p-2">
                  <div className='p-4 bg-gray-50 space-y-2 rounded-lg'>
                    <FcCalendar size={30} className="mx-auto lg:mx-0 " />
                    <div>
                      <h4 className="mb-2 font-medium text-md tracking-tight">יומן עבודה ותורים
                        בזמן אמת</h4>
                      <p className="mb-0 text-sm">צפו בכל התורים שהוזמנו להיום, למחר או לכל תאריך
                        אחר
                        בנוסף ליומן עבודה מסודר המראה לכם מי הבא בתור.</p>
                    </div>
                  </div>
                </div>

                {/*Feature*/}
                <div
                  className="w-full lg:w-1/4 md:w-1/2 text-center lg:text-right mt-4 flex flex-col justify-between p-2">
                  <div className='p-4 bg-gray-50 space-y-2 rounded-lg'>
                    <FcConferenceCall size={30} className="mx-auto lg:mx-0" />
                    <div>
                      <h4 className="mb-2 font-medium text-md tracking-tight">ניהול אנשי צוות</h4>
                      <p className="mb-0 text-sm">נהלו מס׳ נותני שירות אצלכם בעסק באפליקציה אחת. לכל
                        נותן
                        שירות יש יומן עבודה נפרד ורשימת תורים מסודרת.</p>
                    </div>
                  </div>
                </div>

                {/*Feature*/}
                <div
                  className="w-full lg:w-1/4 md:w-1/2 text-center lg:text-right mt-4 flex flex-col justify-between p-2">
                  <div className='p-4 bg-gray-50 space-y-2 rounded-lg'>
                    <FcClock size={30} className="mx-auto lg:mx-0 " />
                    <div>
                      <h4 className="mb-2 font-medium text-md tracking-tight">הגדרת שעות
                        פעילות</h4>
                      <p className="mb-0 text-sm">הגדירו את שעות וימי פעילות העסק, את זמני השירות
                        הממוצעים, אורך כל תור לפי סוג השירות, ימי חופש ועוד.</p>
                    </div>
                  </div>
                </div>
                {/*Feature*/}
                {/* <div
                  className="w-full lg:w-1/4 md:w-1/2 text-center lg:text-right mt-4 flex flex-col justify-between p-2">
                  <div className='p-4 bg-gray-50 space-y-2 rounded-lg'>
                    <FcSms size={30} className="mx-auto lg:mx-0 " />
                    <div>
                      <h4 className="mb-2 font-medium text-md tracking-tight">שליחת הודעות
                        ללקוחות</h4>
                      <p className="mb-0 text-sm">צריכים להודיע משהו לכל הלקוחות שלכם במהירות?
                        השתמשו
                        בממשק המובנה לשליחת הודעות סמס לכלל הלקוחות.</p>
                    </div>
                  </div>
                </div> */}
                {/*Feature*/}
                <div
                  className="w-full lg:w-1/4 md:w-1/2 text-center lg:text-right mt-4 flex flex-col justify-between p-2">
                  <div className='p-4 bg-gray-50 space-y-2 rounded-lg'>
                    <FcExpired size={30} className="mx-auto lg:mx-0 " />
                    <div>
                      <h4 className="mb-2 font-medium tracking-tight text-md">תזכורות אוטומטיות
                      </h4>
                      <p className="mb-0 text-sm">גם לקוחות לפעמים שוכחים את התור שלהם... האפליקציה
                        שלנו
                        תדאג לתזכר אותם לפני התור ולוודא שהם אכן מגיעים.</p>
                    </div>
                  </div>
                </div>
                {/*Feature*/}
                {/* <div
                  className="w-full lg:w-1/4 md:w-1/2 text-center lg:text-right mt-4 flex flex-col justify-between p-2">
                  <div className='p-4 bg-gray-50 space-y-2 rounded-lg'>
                    <FcLike size={30} className="mx-auto lg:mx-0" />
                    <div>
                      <h4 className="mb-2 font-medium tracking-tight text-md">מערכת שימור
                        לקוחות</h4>
                      <p className="mb-0 text-sm">האפליקציה שלנו תדאג לשמור על קשר עם הלקוחות שלכם
                        בזמן
                        המתאים (בימי הולדת, חגים וכו׳).</p>
                    </div>
                  </div>
                </div> */}
                {/*Feature*/}
                <div
                  className="w-full lg:w-1/4 md:w-1/2 text-center lg:text-right mt-4 flex flex-col justify-between p-2">
                  <div className='p-4 bg-gray-50 space-y-2 rounded-lg'>
                    <FcRefresh size={30} className="mx-auto lg:mx-0 " />
                    <div>
                      <h4 className="mb-2 font-medium tracking-tight text-md">שינוי/ביטול תור
                        בלחיצת כפתור</h4>
                      <p className="mb-0 text-sm">אפשרו ללקוחות שלכם לשנות או לבטל את התור שלהם
                        בלחיצת
                        כפתור ומבלי להתקשר אליכם.</p>
                    </div>
                  </div>
                </div>
                {/*Feature*/}
                <div
                  className="w-full lg:w-1/4 md:w-1/2 text-center lg:text-right mt-4 flex flex-col justify-between p-2">
                  <div className='p-4 bg-gray-50 space-y-2 rounded-lg'>
                    <FcViewDetails size={30} className="mx-auto lg:mx-0 " />
                    <div>
                      <h4 className="mb-2 font-medium text-md tracking-tight">רשימת שירותי
                        העסק</h4>
                      <p className="mb-0 text-sm">הוסיפו את שירותי העסק שלכם
                        על מנת לאפשר ללקוחות לקבוע תור לשירות שבו הם מעוניינים.</p>
                    </div>
                  </div>
                </div>

                {/*Feature*/}
                <div
                  className="w-full lg:w-1/4 md:w-1/2 text-center lg:text-right mt-4 flex flex-col justify-between p-2">
                  <div className='p-4 bg-gray-50 space-y-2 rounded-lg'>
                    <FcComboChart size={30} className="mx-auto lg:mx-0 " />
                    <div>
                      <h4 className="mb-2 font-medium tracking-tight text-md">סטטיסטיקות
                        פעילות</h4>
                      <p className="mb-0 text-sm">צפו בסטטיסטיקות על העסק שלכם כגון תורים
                        בהתפלגות יומית, מס׳ ביטולים או שינויים, מס׳ לקוחות חוזרים / חדשים
                        ועוד.</p>
                    </div>
                  </div>
                </div>
                {/*Feature*/}
                {/* <div
                  className="w-full lg:w-1/4 md:w-1/2 text-center lg:text-right mt-4 flex flex-col justify-between p-2">
                  <div className='p-4 bg-gray-50 space-y-2 rounded-lg'>
                    <FcVoicePresentation size={30} className="mx-auto lg:mx-0 " />
                    <div>
                      <h4 className="mb-2 font-medium tracking-tight text-md">בקשת פידבק אוטומטי
                        מלקוחות</h4>
                      <p className="mb-0 text-sm">אנחנו נדאג לבקש חוות דעת מלקוחות לאחר קבלת
                        השירות בכדי שתוכלו ללמוד מה חושבים על העסק שלכם.</p>
                    </div>
                  </div>
                </div> */}
                {/*Feature*/}
                <div
                  className="w-full lg:w-1/4 md:w-1/2 text-center lg:text-right mt-4 flex flex-col justify-between p-2">
                  <div className='p-4 bg-gray-50 space-y-2 rounded-lg'>
                    <FcTimeline size={30} className="mx-auto lg:mx-0 " />
                    <div>
                      <h4 className="mb-2 font-medium tracking-tight text-md">צפייה בהיסטוריית
                        לקוחות</h4>
                      <p className="mb-0 text-sm">צפו בפרטים והפעולות של כל לקוח, כגון תאריך תור
                        ראשון,
                        תורים שבוטלו, תורים עתידיים, טלפון, שם ועוד.</p>
                    </div>
                  </div>
                </div>
                {/*Feature*/}
                {/* <div
                  className="w-full lg:w-1/4 md:w-1/2 text-center lg:text-right mt-4 flex flex-col justify-between p-2">
                  <div className='p-4 bg-gray-50 space-y-2 rounded-lg'>
                    <FcHighPriority size={30} className="mx-auto lg:mx-0 " />
                    <div>
                      <h4 className="mb-2 font-medium tracking-tight text-md">התראה על לקוחות
                        נוטשים</h4>
                      <p className="mb-0 text-sm">האפליקציה תיידע אתכם על לקוחות שהגיעו אליכם
                        אך לא חזרו, בכדי שתוכלו ליצור איתם קשר ולהבין מדוע.</p>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-gray-50 mt-16">
        <div
          className="max-w-7xl text-center lg:text-right mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8 lg:flex lg:items-center lg:justify-between">
          <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            <span className="block">השתכנעתם?</span>
            <span className="block text-green-500">צרו חשבון לעסק שלכם והרגישו בהבדל.</span>
            <span
              className="block text-gray-500 font-normal text-xl p-2 sm:p-0">האפליקציה המקצועית ביותר, לעסקים הטובים ביותר.</span>
          </h2>
          <div className="mt-8 flex lg:mt-0 lg:flex-shrink-0">
            <div className="hidden md:inline-flex rounded-md shadow mx-auto">
              <Link href='/app/signup'>
                <a
                  className="inline-flex items-center justify-center px-6 py-4 border border-transparent text-lg font-medium rounded-lg text-white bg-green-500 hover:bg-green-600">
                  להרשמה מהירה בחינם
                </a>
              </Link>
            </div>

            <div className="inline-flex md:hidden rounded-md shadow mx-auto">
              <Link href='/download/'>
                <a
                  className="inline-flex items-center justify-center px-6 py-4 border border-transparent text-lg font-medium rounded-lg text-white bg-green-500 hover:bg-green-600">
                  לחצו להורדת האפליקציה
                </a>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div>
        <section className="text-gray-700" id='faq'>
          <div className="container px-5 py-24 mx-auto">
            <div className="text-center mb-20">
              <h1 className="text-center text-black text-gray-700 font-bold text-4xl sm:text-4xl w-full px-2">שאלות
                נפוצות</h1>
            </div>
            <div className="flex flex-wrap lg:w-4/5 sm:mx-auto sm:mb-2 -mx-2">
              <div className="w-full lg:w-1/2 px-4 py-2 cursor-pointer">
                {/*<details open={true} className="mb-4">*/}
                {/*	<summary className="font-semibold  bg-gray-100 rounded-md py-2 px-4">*/}
                {/*		מהו אופן התשלום עבור המערכת?*/}
                {/*	</summary>*/}

                {/*	<div className='p-2 text-right'>*/}
                {/*		התשלום עבור השירות הינו חודשי בלבד ואין שום התחייבות לטווח הארוך.*/}
                {/*	</div>*/}
                {/*</details>*/}
                <details open={true} className="mb-4">
                  <summary className="font-semibold bg-gray-100 rounded-md py-2 px-4">
                    למי מתאימה האפליקציה?
                  </summary>

                  <div className='p-2 text-right'>
                    האפליקציה מתאימה לכל עסק הדורש ניהול יומן עם תורים וניהול לקוחות חכם
                    ויעיל.
                  </div>
                </details>
                {/*<details open={true} className="mb-4">*/}
                {/*	<summary className="font-semibold  bg-gray-100 rounded-md py-2 px-4">*/}
                {/*		האם אני יכול לבטל את המנוי בכל שלב?*/}
                {/*	</summary>*/}

                {/*	<div className='p-2 text-right'>*/}
                {/*		בוודאי. אין שום התחייבות. במידה ולא תרצו להמשיך את השימוש במערכת, תוכלו להפסיק*/}
                {/*		בכל שלב ע״י יצירת קשר עם שירות הלקוחות.*/}
                {/*	</div>*/}
                {/*</details>*/}
                <details open={true} className="mb-4">
                  <summary className="font-semibold bg-gray-100 rounded-md py-2 px-4">
                    האם האפליקציה מאובטחת?
                  </summary>
                  <div className='p-2 text-right'>כמובן. אנו שמים דגש על אבטחת המידע ופרטיות, ודואגים
                    שכל פרטי הלקוחות שלכם נשמרים בצורה מאובטחת בענן.
                  </div>
                </details>
                <details open={true} className="mb-4">
                  <summary className="font-semibold bg-gray-100 rounded-md py-2 px-4">
                    האם צריך להוריד אפליקציה?
                  </summary>
                  <div className='p-2 text-right'>לא :) בכדי להפוך את תהליך קביעת התור לנוח ומהיר עבור לקוחותיכם, הכל
                    מתבצע דרך דף העסק אותו אתם יוצרים. בלי אפליקציות מסובכות!
                  </div>
                </details>
              </div>
              <div className="w-full lg:w-1/2 px-4 py-2 cursor-pointer">
                <details open={true} className="mb-4">
                  <summary className="font-semibold bg-gray-100 rounded-md py-2 px-4">
                    האם האפליקציה נגישה דרך הטלפון?
                  </summary>

                  <div className='p-2 text-right'>
                    ברור! התאמנו את האפליקציה לעבוד בצורה הטובה ביותר דרך הטלפון הנייד שלכם, כך שתוכלו
                    לנהל את העסק שלכם מכל מקום ובכל זמן.
                  </div>
                </details>
                <details open={true} className="mb-4">
                  <summary className="font-semibold bg-gray-100 rounded-md py-2 px-4">
                    האם יש תמיכה ועזרה ללקוחות?
                  </summary>

                  <div className='p-2 text-right'>
                    בוודאי. אנו נדאג לטפל בכל בעיה או בקשה שעולה לגבי השימוש באפליקציה. לא סתם יש לנו
                    מאות לקוחות מרוצים :)
                  </div>
                </details>

                <details open={true} className="mb-4">
                  <summary className="font-semibold bg-gray-100 rounded-md py-2 px-4">
                    האם התוכנה רשומה ברשות המיסים?
                  </summary>

                  <div className='p-2 text-right'>
                    כמובן! ע״י שימוש בתוכנה שלנו לניהול התורים והעסק שלכם, אתם יכולים להיות רגועים שאתם עומדים בדרישות החוק.
                  </div>
                </details>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>)
}

export async function getServerSideProps({ params }) {
  const { keyword } = params;
  const displayed = keyword.replace(/-/g, " ");
  return {
    props: { keyword, displayed }
  }
}

Keyword.Layout = WebsiteLayout;
